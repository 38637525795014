.App {
  text-align: center;
}

.App-logo {
  height: 80px;
}

.App-header {
  background-color: #9239cb;
  /* height: 75px; */
  padding: 20px;
  color: white;
}
